<template>
  <div>
    <b-row>
      <b-col>
        <h2>Generate Invoice Mailing</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <span>Select Invoices</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2">
        <b-form-group
          label="Start Date"
          label-for="startDate"
        >
          <DOBInput v-model="StartDate" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="End Date"
          label-for="endDate"
        >
          <DOBInput v-model="EndDate" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="primary"
          class="mt-2 ml-2"
          @click.prevent="applyDates()"
        >
          Apply Dates
        </b-button>
      </b-col>
      <b-col md="2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="outline-primary"
          class="mt-2 ml-2"
          @click.prevent="yesterday()"
        >
          Yesterday
        </b-button>
      </b-col>
      <b-col md="2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="outline-primary"
          class="mt-2"
          @click.prevent="lastThreeDays()"
        >
          Last 3 Days
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span>{{ totalInvoices }} invoices</span>
      </b-col>
    </b-row>
    <b-row>
      <!-- Table -->
      <b-col class="white-bg-container">
        <b-row>
          <b-col md="1">
            <b-form-checkbox
              id="checkAll"
              v-model="CheckAll"
              name="checkAll"
            >
              All
            </b-form-checkbox>
          </b-col>
          <b-col md="4">
            Invoice Number
          </b-col>
          <b-col md="2">
            Case Number
          </b-col>
          <b-col md="3">
            Client - Branch
          </b-col>
          <b-col
            md="1"
            class="text-nowrap "
          >
            PDF Download
          </b-col>
        </b-row>
        <b-row
          v-for="(item, index) of Invoices"
          :key="index"
        >
          <b-col>
            <b-row class="pt-1">
              <b-col md="1">
                <span>
                  <b-form-checkbox
                    :id="'checkAll' + item.InvoiceID"
                    v-model="item.checked"
                    name="checkAll"
                  />
                </span>
              </b-col>
              <b-col md="4">
                <span>
                  <router-link :to="'/accounting/invoice-summary/' + item.InvoiceID">
                    <span class="text-nowrap">{{ item.InvoiceDisplayNumber }}</span>
                  </router-link>
                </span>
              </b-col>
              <b-col md="2">
                <span>
                  <router-link :to="'/cases/' + item.CaseID + '/detail'">
                    <span class="text-nowrap">{{ item.FileNumber }}</span>
                  </router-link>
                </span>
              </b-col>
              <b-col md="3">
                <span>{{ item.BranchName }}</span>
              </b-col>
              <b-col md="1">
                <span
                  class="d-flex center-justified align-content-center cursor-pointer"
                  @click="pdfDownload(item)"
                >
                  <img
                    class="m-auto"
                    src="../../assets/images/icons/PDFDownload.png"
                  >
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="primary"
          class="mt-2 ml-2"
          @click.prevent="printSelectedInvoices()"
        >
          Print Selected Invoices
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BFormCheckbox, BFormGroup, BRow} from "bootstrap-vue";
import DOBInput from "@core/components/dobInput/DOBInput";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import axios from "axios";
import { saveAs } from 'file-saver';

const axiosIns = axios.create({
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/pdf',
  },
})

const apiService = new APIService();

const options = {month: '2-digit', day: '2-digit', year: 'numeric'}

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    DOBInput,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      StartDate: new Date(new Date().setHours(0,0,0,0)).toLocaleDateString("en-US", options),
      EndDate: new Date(new Date().setHours(23,59,59,999)).toLocaleDateString("en-US", options),
      CheckAll: true,
      Invoices: [],

      totalInvoices: 0,
    }
  },
  watch: {
    CheckAll(val) {
      this.Invoices = this.Invoices.map(item => {
        return {...item, "checked": val}
      })
    },
  },
  created() {
    this.loadInvoiceList();
  },
  methods: {
    loadInvoiceList(StartDate = new Date, EndDate = new Date) {
      const postData = {
        "StartDate": new Date(new Date(StartDate).setHours(0,0,0,0)).toISOString(),
        "EndDate": new Date(new Date(EndDate).setHours(23,59,59,999)).toISOString(),
      }
      apiService
          .post("invoice/bulk-print-list", postData)
          .then( res => {
            this.Invoices = res.data.map(item => {
              return {...item, checked: true}
            });
            if (this.Invoices.length) this.totalInvoices = this.Invoices.length;
          })
    },
    applyDates() {
      this.loadInvoiceList(this.StartDate, this.EndDate)
    },
    yesterday() {
      this.StartDate = new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString("en-US", options);
      this.EndDate = new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString("en-US", options);
    },
    lastThreeDays() {
      this.StartDate = new Date(new Date().setDate(new Date().getDate() - 3)).toLocaleDateString("en-US", options);
      this.EndDate = new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString("en-US", options);
    },
    pdfDownload(item) {
      axiosIns.get(item.PdfUrl)
          .then(response => {
            saveAs(new File([response.data], "", {type: 'application/pdf'}))
          })
    },
    printSelectedInvoices() {
      const InvoiceIds = this.Invoices.filter(i => i.checked === true).map(i => i.InvoiceID);
      const postData = InvoiceIds.join(",")
      axiosIns
          .get("/api/invoice/bulk-print?InvoiceIds=" + postData)
          .then(response => {
            saveAs(new File([response.data], "", {type: 'application/pdf'}))
          })
    },
  },
}
</script>

<style scoped>

</style>