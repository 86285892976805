<template>
  <div>
    <input
      id="DOB"
      type="date"
      placeholder=""
      :value="dobValue"
      :readonly="readonly"
      :class="{blur: readonly}"
      @input="eventTarget"
    >
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    readonly: Boolean,
  },
  data() {
    return {
      dobValue: "",
    }
  },
  watch: {
    value(val) {
      this.dateEntry(val);
    },
  },
  mounted() {
    this.dateEntry(this.value);
  },
  methods: {
    eventTarget($event) {
      const date = $event.target.value;
      this.DateOfBirth(date);
    },
    dateEntry(val) {
      if (val) {
        const date = val.substr(0, 10)
        this.DateOfBirth(date)
      }
    },
    DateOfBirth(date) {
      const output = new Date(date + " 0:00 UTC").toISOString().substr(0, 10);
      this.dobValue = output;
      this.$emit('input', output)
    },
  },
}
</script>

<style scoped>
input:focus-visible {
  outline: none;
  border: 1px solid #476dae;
  box-shadow: 0 2px 5px -2px #476dae;
}
input {
  border: 1px solid #d8d6de;
  border-radius: 0.3rem;
  width: 100%;
  padding: 0.5rem;
}
.blur {
  background-color: #efefef;
}
#DOB {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.45;
  color: #6e6b7b;
}
</style>